.calendar {
    font-family: Arial, sans-serif;
    text-align: center;
    margin: 20px auto;
    width: 300px;
  }
  
  .calendar header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: transparent;
  }
  
  .calendar header button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .calendar table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .calendar th,
  .calendar td {
    padding: 5px;
    /*border: 1px solid #ddd;*/
  }
  
  .calendar .empty-cell {
    background-color: transparent;
  }
  
  .calendar th,
  .calendar td {
    width: 14.28%;
  }
  
  .calendar th {
    background-color: transparent;
    font-weight: bold;
    border-bottom: 1px solid black;
  }
  
  .calendar td {
    cursor: pointer;
  }
  
  .calendar td:hover {
    background-color: #FF2D52;
    border-radius: 50px;
  }

  .selected {
    background-color: #FF2D52;
    border-radius: 50px;
  }

  .calendar table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Añade esta línea para asegurarte de que las celdas tengan el mismo ancho */
  }
  
  .calendar th,
  .calendar td {
    padding: 10px;
    /*border: 1px solid #ddd;*/
    width: 14.28%;
    text-align: center;
  }