@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 60px;
    height: 60px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #FF2D52; /* Black */
    border-radius: 50%;
    animation: spinner 1.2s linear infinite;
    margin-top: 50px;
  }