
@font-face
{
  font-family: 'geometria';
  font-style: normal;
  src: url('../src/font/Geometria.ttf') format('truetype');
}

@font-face
{
  font-family: 'geometriabold';
  font-style: normal;
  src: url('../src/font/Geometria-Bold.ttf') format('truetype');
}

@font-face
{
  font-family: 'geometriaextrabold';
  font-style: normal;
  src: url('../src/font/Geometria-ExtraBold.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: auto;
}

body p{
  font-family: 'geometriaextrabold';
}

body h2{
  font-family: 'geometria';
}

body h3{
  font-family: 'geometria';
}

body h4{
  font-family: 'geometria';
}

body h5{
  font-family: 'geometria';
}

body h6{
  font-family: 'geometria';
}

body h7{
  font-family: 'geometria';
}

body label{
  font-family: 'geometria';

}

body label b{
  font-family: 'geometriaextrabold';
  color: black;
}

body input{
  font-family: 'geometria';
}

body select{
  font-family: 'geometria';
}

body p b{
  font-family: 'geometriaextrabold';
  color: black;
}

body ul li{
  font-family: 'geometriaextrabold';
  color: black;
}

body button{
  font-family: 'geometria';
  color: black;
}

#footer1
{
  
  background-color: #000 !important;
  color: #D8D8D8 !important;
  font-size: 9pt !important;
}

#footer2
{
  background-color: #000 !important;
}

#footer3
{
  background-color: #000 !important;
}

.FooterNew
{
  background-color: black !important;
}

.FooterNew a
{
  color: white !important;
}


/*Home*/


.btn{
  width:calc(6em + 6vw) !important;
  height:calc(1.3em + 1.3vw) !important;
}

.btn-primary2
{
  font-size: 9pt !important;
  color: #FF2D52 !important;
  background-color: #D8D8D8 !important;
  padding: 0 0 0 0 !important;
  width: 100% !important;
}

.btn-primary3
{
  font-size: 9pt !important;
  width: 100% !important;
  min-height: 30px !important;
  color: white !important;
  background-color: #FF2D52 !important;
  padding: 0 0 0 0 !important;
}

.btn-primary4
{
  font-size: 12pt !important;
  color: white !important;
  background-color: #313131 !important;
  padding: 0 0 0 0 !important;
  height: 50px !important;
  width: 190px !important;
  border-radius: 15px !important;
}
.btn-primary5
{
  font-size: 6pt !important;
  color: black !important;
  background-color: transparent !important;
  padding: 0 0 0 0 !important;
  border: 1px solid #9c9c9c !important;
}

.btn-primary6
{
  font-size: 7pt !important;
  color: #FFE521 !important;
  background-color: #000 !important;
  padding: 0 0 0 0 !important;
}

.btn-primary7
{
  font-size: 9pt !important;
  width: 100% !important;
  min-height: 30px !important;
  color: black !important;
  background-color: white !important;
  padding: 0 0 0 0 !important;
  border:1px solid #412F8C !important;
  border-radius: 20px !important;
}

.btn-primary8
{
  font-size: 9pt !important;
  width: 100% !important;
  min-height: 30px !important;
  color: white !important;
  background-color: #92BB00 !important;
  padding: 0 0 0 0 !important;
}

.btn-primarybuscar
{
  font-size: 10pt !important;
  color: white !important;
  background-color: #FF2D52 !important;
  padding: 0 0 0 0 !important;
  width: 100% !important;
  height: 100% !important;
  border: none !important;
  font-weight: bold;
  border-radius: 5px;
}

.btn-primary9
{
  font-size: 19pt !important;
  color: #FFE521 !important;
  background-color: #313131 !important;
  padding: 0 0 0 0 !important;
  height: 50px !important;
  width: 190px !important;
  border-radius: 15px !important;
  font-style: oblique;
  font-weight: bold !important;
}

.btn-primary10
{
  font-size: 9pt !important;
  color: #FF2D52 !important;
  background-color: #D8D8D8 !important;
  padding: 0 0 0 0 !important;
  width: 50% !important;
}

#imgLogoPrincipal{
  align-content: center;
  padding-top:30px;
  width:700px;

}

.text-white
{
  z-index: 1 !important;
 font-size: 14pt !important;
 height: 60px !important;
 width: 100% !important;
 border:1px solid #D8D8D8  !important;
 align-items: center !important;
 padding: 15px 15px 15px 15px !important;
 border-radius: 10px;
 background-color: white;
}
.searchconfig{
  height:auto !important;
}

.borderInputSearch input
{
  border: none !important;
  width: 100% !important;
  font-size: 10pt !important;
  outline: none !important;
 
}

.text-white input{
  width: 100% !important;
  height: 100% !important;
  border: none;
  outline: none;
}

.imglupa
{
  
  height:30px;
}

.imglupa2
{
  
  height:20px;
  padding-left: 0;
}

#divPlanes{
  list-style: none;
  margin: 0;
  padding: 0 0 0 0;
  overflow-x: auto;
}

#divPlanes .planes{

  width: 20%;
  display: inline-block;
 
}

#topmerkasanew{
  list-style: none;
  margin: 0;
  padding: 0 0 0 0;
  overflow-x: auto;
}

#topmerkasanew .toplinew{
  float: left;
	padding: 0 0 0 0;
  margin: 15px 15px 15px 15px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  display: block;
  width: 30%;
  cursor: pointer;
}

#topmerkasanew .toplinew2{
  float: left;
	padding: 0 0 0 0;
  margin: 15px 15px 15px 15px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  display: block;
  width: 30%;
  cursor: pointer;
}

#ulBusqueda{
  list-style: none;
  margin: 0;
  padding: 0 0 0 0;
  overflow-x: auto;
}

#ulBusqueda .ulbuquedacss{
  float: left;
	padding: 0 0 0 0;
  
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  width: 100%;
  cursor: pointer;
}

.arrow {
  border:solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 10px;
  cursor: pointer;
  height: 20px;
  width: 19px;
  -moz-box-shadow: 2px 4px 4px #9c9c9c;
	-webkit-box-shadow: 2px 4px 4px #9c9c9c;
	box-shadow: 2px 4px 4px #9c9c9c;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
 
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.circle {
  width: 35px;
  height: 35px;
  border-radius: 50%; /* Hace que el elemento sea un círculo */
  margin: -6px;
  display: inline-block;
  font-size: 12pt;
  font-weight: bold;
}

.red {
  background-color: #FFCDCD;
  color: #AE4D4D;
}

.green {
  background-color: #8CFFF7;
  color: #23A59C;
}

.blue {
  background-color: #B1E174;
  color: #779300;
}

.split-container {
  display: flex; /* Utilizar flexbox para el diseño */
  height: 100vh; /* Alto de la ventana del navegador */
  overflow: hidden;
}


.split-left,
.split-right {
  flex: 1; /* Distribuir el espacio de manera igual entre los dos divs */
  box-sizing: border-box;
}

.split-left {
  background-color: transparent; /* Color del fondo del lado izquierdo */
}

.split-right {
  background-color: transparent; /* Color del fondo del lado derecho */
}

.tituloEventos
{
  font-size:22pt;
  text-align:left !important;
 
}

.imgisotipo
{
  text-align:left;
  padding-top:10px;
  padding-bottom:20px;
}

/*Fin Home*/

/*Ini Home User*/

.imgCbo
{
  background-image: url('../src/img/HomeUser/drop_down.svg') !important;
  background-size: 20px !important;
  font-size:10pt !important;
  color:black !important;
  border:none !important;
}

.bullet{
  background-image: url('../src/img/CrearGrupo/bullet.svg') !important;
  background-size: 20px !important;
}

.ContainerPreCrearGrupo
{
  border-radius: 20px;
  background-color: rgba(146, 187, 0, 0.1);
  font-weight: bold;
  padding-top: 10px;
  font-size: 11.5pt;
  height: calc(2.0em + 2.0vw);
}

.popupmenu{

  height: calc(10em + 10vw);
  background-color: white;
  width: calc(7em + 7vw);
  z-index: 4;
  position: absolute;
  top: 7%;
  right: 11%;
  border-radius: 10px;
  font-size: 10pt;
  display:  none;
}

.popupmenu.activo{
  height: calc(240px + 2vmin);
  background-color: white;
  width: calc(190px + 2vmin);
  z-index: 4;
  position: fixed;
  top: 7%;
  right: 10%;
  border-radius: 10px;
  font-size: 10pt;
  display: block;
}

.popupmenu_ul{
  list-style: none;
  margin: 0;
  padding: 0 15px 0 15px;
  text-align: left;
}

.popupmenu_ul li{
  width: 100%;
  padding-top: 25px;
 /* border-bottom:1px solid #D7D7D7;*/
  cursor: pointer;
  font-size: 10pt;
}

.popupcarac_ul{
  list-style: none;
  margin: 0;
  padding: 0 0 0 0;
  display: block;
}

.popupcarac_ul li{
	float: left;
	padding: 0 0 0 0;
	position: relative;
  max-height: 50%;
  width:20%;
  cursor: pointer;
}

.btncaracteristicas
{
  border:1px solid #ddd;
  width: 100%;
  height: 50px;
  align-items: center;
  border-radius: 20px;
}

.btncaracteristicas.activo
{
  background-color: rgba(146, 187, 0, 0.8);
  font-weight: bold;
  padding: 0 0 0 0;
  border-radius: 20px;  
}

#wimagen
{
  height: auto;
  min-height: 300px;
  width: 100%;
	padding: 0 0 0 0;
	position: relative;
  -moz-box-shadow: 2px 4px 4px #9c9c9c;
	-webkit-box-shadow: 2px 4px 4px #9c9c9c;
	box-shadow: 2px 4px 4px #9c9c9c;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

#listaAnunciosfotos .anuncioslif{
	float: left;
	padding: 30px 80px 0 5px;
	position: relative;

text-align: center;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  max-height: 50%;
  width:20%;
}

.btnEliminarFoto
{
  width: 200px !important;
  float: center !important;
}

.canvasperfil {
  object-fit: cover;
  width: 150px;
  height: 150px;
  border-radius: 10px;
  z-index: -1;
}

.bgperfil
{
  border:1px solid #9c9c9c;
  width:150px;
  height:150px;
  position:relative;
  border-radius:10px;
  background-image: url('../src/img/CrearGrupo/subir_imagen.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#mapaDetalle {
  float: right;
  width: 100%;
  height: calc(7em + 7vw);
  overflow: hidden;
  border-radius: 10px;
}
/*Fin HomeUser*/

#listaAnunciosf
{
  list-style: none;
  margin: 0;
  padding: 0 0 0 0;
  display: block;
}

#listaAnunciosf .anunciosli{
  float: left;
  padding: 0 0 0 0;
  position: relative;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  max-height: 50%;
  width:50% !important;
  cursor: pointer;
}

.imgisotipo_f{
  width: '15vw' !important; 
  float: 'left' !important;
}

.LogoPrincipal
{
  width: '5vw' !important;
}

.SloganPrincipal
{
  width: calc(10em + 10vw);
}

.TextoHomePrincipal
{
  font-size: calc(0.7em + 0.7vw);
}

.TextoHomePrincipal b
{
  color: white !important;
}

.TextBusqueda
{
  text-align: left;
  font-size: 8pt; 
  padding: '0 0 0 0'
}

.TextBusqueda2
{
  text-align: left;
  font-size: 18pt; 
  padding: '0 0 0 0'
}
.LetrasEventos
{
  text-align:left;
}
.TextAsistentes
{
  font-size: 12pt; 
  padding: '0 0 0 0';
  text-align: left;
  margin-top: 10px;

}
/* VideoPlayer.css */
.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* Proporción 16:9 para hacerlo responsive */
}

.react-player {
  position: absolute;
  top: calc(-3.2em + -3.2vw);
  left: 0;
  z-index: -1;
  /*width: 100% !important;*/
  width:100% !important;
  height: calc(100% + 450px) !important;

}

.react-padre{
  overflow: auto !important;
}

.react-player-2 {
  position: absolute;
  left: 0;
  z-index: -1;
  /*width: 100% !important;*/
  width: 100% !important;
  height: calc(100% + 50px) !important;
 bottom: 0 !important;

}


.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 190px);
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.3); /* Color de fondo con opacidad del 30% */
 
}

.fondoIcon
{
  border:1px solid #000;
  width:50%;
  height:calc(1.6em + 1.6vw);
  padding:10px 10px 10px 10px;
  border-radius: 10px;
  background-color: #313131;
}

.minHeight{
  min-height: 100%;
  position: relative;
}

.paddingTop{
  padding-top: none !important;
}

.btnEncuentraCrea
{
  cursor: pointer;
  width:100%;
}

.textHomeVinetas{
  padding-top: none;
  font-size:calc(0.8em + 0.8vw);
  text-align:justify;
}

#headerprincipal
{
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  border: 1px solid black;
  background: black;
  position: fixed;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btnConectarme{
  font-family: 'geometriaextrabold' !important;
}


@media only screen and (max-width: 768px) {


  .titleQsomos3
  {
    display: none !important;
  }
  
  .imgQSomos3
  {

    display: none !important;
  }
  
  .textQSomos3
  {
    display: none !important;
  }

#imgLogoPrincipal{
  width:calc(13em + 13vw) !important;

}

#headerprincipal
{

  border: 1px solid white !important;
}

.text-white
{
 padding: 10px 10px 10px 10px !important;
 font-size: 12pt !important;
}

.text-white input{
  text-align: center !important;
}

.imglupa{
  padding-top:10px !important;
}

.btnConectarme{
  padding-top:25px !important;
}

#divPlanes .planes{
overflow: hidden !important;
padding: 10px 0 0 0;
height: 250px !important;

}

#divPlanes .planes p{
 font-size: 8pt !important;
  }


  #topmerkasanew .toplinew{
width: 100% !important;
padding-left: calc(2em + 2vw) !important;
margin: 0 !important;
  }

  #topmerkasanew .toplinew2{
    width: 100% !important;
    padding-left: calc(0.8em + 0.8vw) !important;
    margin: 0 !important;
    overflow: hidden !important;
    
      }

  .tituloEventos
{
  text-align:center !important;
  padding-left:0 !important;

}

.imgisotipo
{
  text-align:right !important;
}

.imgmobilvineta
{
  width: calc(3em + 3vw) !important;
}

.imgisotipo_f{
  width: 15vw !important; 
  float: left !important;
}

.SloganPrincipal
{
  width: calc(11em + 11vw) !important;
}

.TextBusqueda
{
  text-align: center !important;
}

.TextBusqueda2
{
  text-align: center !important;
}

.LetrasEventos
{
  text-align: center !important;
}

.TextAsistentes
{
  margin-top: 15px !important;
  text-align: center !important;
}

.ContainerPreCrearGrupo
{

  padding-top: 10px !important;
  font-size: 7pt !important;
  height: calc(2.5em + 2.5vw) !important;
}

.video-overlay {
  height: calc(30em + 30vw) !important;
  /*display: none !important;*/
  background-color: rgba(0, 0, 0, 1)
}

.react-player {
  /*top: calc(6em + 6vw) !important;*/
  left: none !important;
  width: 100% !important;
  height: 100% !important;

}
.minHeight{
  
  min-height: calc(14.5em + 14.5vw) !important;
  
}

.paddingTop{
  padding-top: calc(2em + 2vw) !important;
}

.btnEncuentraCrea
{
  width:40% !important;
  padding-top: 10px !important;
}

.textHomeVinetas{
  padding-top: calc(0.7em + 0.7vw) !important;
  font-size:calc(0.7em + 0.7vw) !important;
}

.planesResponsive
{
  padding-left: 100px !important;
  padding-right: 100px !important;
  padding-top: 20px !important;
}

.planesResponsive p 
{
  font-size: 18pt !important;
}

.tipoMOneda
{
  height: 100% !important;
}

.LogoHeader
{
  display: block !important;
}

.lblBienvenida
{
  font-size:24pt !important;
  padding-top:calc(1.3em + 1.3vw) !important;
}

}

.lblBienvenida
{
  font-size:27pt;
  /*font-style:italic*/;
  padding-top:calc(0.2em + 0.2vw);
}

@media screen and (width: 360px) {
  .react-player {
    top: calc(14em + 14vw) !important;

  
  }
  .titleQsomos3
  {
    display: none !important;
  }
  
  .imgQSomos3
  {

    display: none !important;
  }
  
  .textQSomos3
  {
    display: none !important;
  }
}

@media screen and (width: 375px) {
  .react-player {
    top: calc(12em + 12vw) !important;

  
  }
  .titleQsomos3
  {
    display: none !important;
  }
  
  .imgQSomos3
  {

    display: none !important;
  }
  
  .textQSomos3
  {
    display: none !important;
  }
}



.LogoHeader{
  display: block;
  width: 100%;
  max-width: 210px; 
  cursor: pointer;
  padding-left:-10px;
}

.LogoHeader2{
  display: block;
  width: 100%;
  max-width: 210px; 
  cursor: pointer;
  padding-left:0;
}

.tipoMOneda{
  height: calc(1.1em + 1.1vw);
}

.divPlanes_2 {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.planes_2 {
  margin: 5px;
  text-align: center;
}

.planes_2 img {
  object-fit: cover;
  width: 100%;
  max-width: calc(6em + 6vw); 
  border-radius: 10px !important;
}

.planes_2 p {
  font-size: 14pt;
}

/* Media queries para ajustar estilos en pantallas más grandes */
@media screen and (min-width: 600px) {
  .planes_2 {
    flex-basis: calc(33.3333% - 20px); /* Tres elementos por fila, con márgenes */
  }
 
}

@media screen and (min-width: 900px) {
  .planes_2 {
    flex-basis: calc(25% - 70px); /* Cuatro elementos por fila, con márgenes */
  }

}

.containerImageHome
{
  padding-top:calc(2em + 2vw);
  min-height:calc(20em + 20vw);
  padding-bottom:calc(1em + 1vw);
  padding-left:0;
  padding-right:0;
}

/* En tu archivo CSS o en línea de estilo dentro del componente */
.form_generator {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 0 auto;
}

.form_generator label {
  margin-bottom: 10px;
}

.form_generator label input {
  padding: 8px;
  margin-bottom: 10px;
}

.form_generator button {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  cursor: pointer;
}


nav {
  display: flex;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

nav li {
  margin-right: 20px;
}

nav a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

nav a:hover {
  text-decoration: underline;
}

.tamaniobuscador
{
  position: relative;
  
  text-align: center !important;
}

.carrusel2 {
  display: flex;
  overflow-x: hidden; /* Oculta los elementos que desbordan horizontalmente */
  overflow-y: hidden;
  list-style-type: none;
  padding-left: 0 !important;
  z-index: 1;
}

.toplinew2 {
  flex: 0 0 30% !important; /* Ancho fijo para cada elemento, ajusta según sea necesario */
  padding: 0 10px 0 0 !important; /* Espacio entre elementos */
}

/* Animación de carrusel */
@keyframes desplazar-carrusel {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}

/*
.carrusel2 {
  animation: desplazar-carrusel 10s linear infinite;
}*/

.styled-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
}

.styled-table th, .styled-table td {
  padding: 8px;
  text-align: left;
}

.styled-table th {
  background-color: #f2f2f2;
}

.styled-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.styled-table tbody tr:hover {
  background-color: #ddd;
}


.containerValidaContrasenia {
  text-align: center;
  background-color: white;
  padding: 30px;
  /*border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);*/
}
.input-groupValidaContrasenia {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.input_ValidaContrasenia {
  font-size: 2em;
  text-align: center;
  width: 50px;
  height: 50px;
  border: 1px solid #92BB00;
  border-radius: 25px;
}
.button_ValidaContrasenia {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 4px;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
}
.button_ValidaContrasenia:hover {
  background-color: #0056b3;
}

.carrusel2 li {
    flex: 1 1 30%;
    margin-bottom: 1rem;
}

.carrusel2 li:hover {
    transform: scale(1.05);
}

.toplinew2 {
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.toplinew2:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.toplinew2 img {
    max-width: 100%;
    border-radius: 10px;
}

/*cambios 11/27*/

.carrusel2 {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.carrusel2::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.carrusel2 li {
  flex: 0 0 auto;
  scroll-snap-align: start;
  margin-right: 1rem;
}

.carrusel2 li:hover {
  transform: scale(1.05);
}

.toplinew2 {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.toplinew2:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.toplinew2 img {
  max-width: 100%;
  border-radius: 10px;
}

/* Excepción para la página con la clase `no-bold-paragraphs` */
body.no-bold-paragraphs p {
  font-family: geometria; /* O la fuente que desees usar */
}

.titleQsomos3
{
  display: block;
  padding-top: 40px;
}

.imgQSomos3
{
  width: 100%;
  object-fit: cover;
  padding: 40px;
  display: block;
}

.textQSomos3
{
  font-size: 10pt;
  text-align: justify;
  padding-top: 40px;
  display: block;
}

.textQSomosMobile{
  font-size: 10pt;
  text-align: justify;
  padding: 40px;
  display: none;
}

.imgQSomosMobile{
  margin: 40px;
  width: 100%;
  object-fit: cover;
  padding-left: 40px;
  display: none;
}

.titleQSomosMobile{
  margin: 40px;
  display: none;
}